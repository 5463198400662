<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <listitems
          :list="process_list"
          :qDelete="Qdeleteprocess"
          :qCreate="Qcreateprocess"
          :qUpdate="Qupdateprocess"
          :Get_suf="'Process'"
          :title="'Liste des Gammes '"
          :headers="headers"
          :filename="'Gammes'"
          :sheetname="'Gammes'"
          :key="klist"
          :showstd="true"
          @rowselect="ProcessChange"
          :add="editer"
          :Update="editer"
          :del="editer"
          :del_disable="gammes_list.length > 0"
          :laoding="loading"
        >
        </listitems>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <listitems
          :list="gammes_list"
          :title="'Process'"
          :qDelete="Qdeleteprocessgamme"
          :qCreate="Qcreateprocessgamme"
          :qUpdate="Qupdateprocessgamme"
          :Get_suf="'ProcessGamme'"
          :headers="gammes_headers"
          :key="kgl"
          :master="false"
          :add="true"
          :del="true"
          :showstd="true"
          :push="true"
          :exp_excel="false"
          @rowselect="GammeChange"
          :del_disable="boms_list.length > 0"
          :add_disable="process.id > 0"
          :filename="'Process'"
          :sheetname="'Process'"
        >
        </listitems>
      </v-col>
    </v-row>
    <template>
      <confirmdialog ref="confirm" />
    </template>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import DELETE_PROCESS from "../graphql/Process/DELETE_PROCESS.gql";
import CREATE_PROCESS from "../graphql/Process/CREATE_PROCESS.gql";
import UPDATE_PROCESS from "../graphql/Process/UPDATE_PROCESS.gql";

import DELETE_PROCESS_GAMME from "../graphql/Process/DELETE_PROCESS_GAMME.gql";
import CREATE_PROCESS_GAMME from "../graphql/Process/CREATE_PROCESS_GAMME.gql";
import UPDATE_PROCESS_GAMME from "../graphql/Process/UPDATE_PROCESS_GAMME.gql";

import PROCESS from "../graphql/Process/PROCESS.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },

  data: () => ({
    loading: false,
    editer: false,
    klist: 400,
    editedItem: {},
    kbom: 100,
    kgl: 200,
    pf: 300,

    process: {},
    gamme: {},
    bom: {},
    isBomClose: true,
    headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Date creation",
        value: "created_at",
        selected: true,
      },
      {
        text: "Reference",
        value: "ref",
        edit: true,
        type: "text",
        rules: [(v) => !!v || "Reference obligatoire"],
        sm: 3,
        md: 3,
        selected: true,
      },
      {
        text: "Gamme",
        value: "name",
        rules: [(v) => !!v || "Gamme obligatoire"],
        slot: "href",
        edit: true,
        type: "text",
        sm: 6,
        md: 9,
        selected: true,
      },
      {
        text: "Caption",
        value: "caption",
        edit: true,
        type: "text",
        sm: 6,
        md: 6,
        selected: true,
      },
      {
        text: "Inclu",
        value: "included",
        selected: true,
        slot: "checkbox",
        edit: true,
        type: "check-box",
        sm: 3,
        md: 3,
      },
      {
        text: "Description",
        value: "description",
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
        hiden: true,
      },
    ],

    gammes_headers: [
      {
        text: "process_id",
        value: "process_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
      {
        text: "Ordre",
        value: "order",
        selected: true,
        edit: true,
        rules: [(v) => !!v || "Ordre obligatoire"],
        type: "number",
        sm: 2,
        md: 2,
      },
      {
        text: "Gamme",
        value: "gamme_id",
        selected: true,
        edit: true,
        rules: [(v) => !!v || "Gamme obligatoire"],
        slot: "simple-select",
        type: "select",
        show_list: [],
        item_value: "id",
        item_text: "name",
        sm: 6,
        md: 6,
      },
    ],

    process_list: [],
    gammes: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),
  watch: {},
  computed: {
    Qcreateprocess() {
      return CREATE_PROCESS;
    },
    Qupdateprocess() {
      return UPDATE_PROCESS;
    },
    Qdeleteprocess() {
      return DELETE_PROCESS;
    },
    Qcreateprocessgamme() {
      return CREATE_PROCESS_GAMME;
    },
    Qupdateprocessgamme() {
      return UPDATE_PROCESS_GAMME;
    },
    Qdeleteprocessgamme() {
      return DELETE_PROCESS_GAMME;
    },
    gammes_list() {
      let l = [];
      if (this.process) if (this.process.gammes) l = this.process.gammes;
      return l;
    },
    boms_list() {
      let l = [];
      if (this.gamme) if (this.gamme.boms) l = this.gamme.boms;
      return l;
    },
  },
  created() {},

  async mounted() {
    this.editer =
      this.$store.state.auth.includes("02054") || this.$store.state.isadmin;
    this.loading = true;
    await this.$apollo
      .query({
        query: PROCESS,
      })
      .then((data) => {
        this.process_list = data.data.process;
        this.klist++;
        this.gammes = data.data.gammes;
        this.gammes_headers[2].select_list = this.gammes;
        this.gammes_headers[2].show_list = this.gammes;
        this.loading = false;
      });
  },

  methods: {
    GammeChange(item) {
      this.gamme = item;
    },
    ProcessChange(item) {
      this.process = item;
      this.gamme = {};
      this.gammes_headers[0].default = this.process.id;
      this.kgl++;
    },
  },
};
</script>

<style></style>
